import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { quickFetchPropertiesFromV4 } from './propertiesEarlyRequester';
function quickListingLibAppSettings() {
  getFrameworkDataQuickFetchClient().then(client => {
    client.graph.frameworkTypes.getObjects({
      appSettingNames: ['frameworkListingLib']
    });
  }).catch(error => {
    console.error(error);
  });
}
function quickFetchCrmObjectTypeDefinitions() {
  quickFetch.makeEarlyRequest(`fetch-crm-object-type-definitions`, {
    url: quickFetch.getApiUrl(`/inbounddb-meta/v1/object-types/for-portal`, true),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json'
  });
}
function quickFetchGraphQL(objectTypeId) {
  makeGqlEarlyRequest({
    url: quickFetch.getApiUrl('/graphql/crm'),
    operation: {
      operationName: 'ListingLibQuery',
      // @ts-expect-error defined in webpack
      query: importGql('ListingLibQuery'),
      variables: {
        objectTypeId
      }
    }
  });
}
export function quickFetchListingLib(objectTypeId) {
  quickListingLibAppSettings();
  quickFetchCrmObjectTypeDefinitions();
  quickFetchPropertiesFromV4(objectTypeId);
  quickFetchGraphQL(objectTypeId);
}
export function quickFetchListingLibNoCrm() {
  quickListingLibAppSettings();
}
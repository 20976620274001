import quickFetch from 'quick-fetch';
function makePropertiesV4EarlyRequest(objectTypeId) {
  quickFetch.makeEarlyRequest(`fetch-crm-properties-${objectTypeId}`, {
    url: quickFetch.getApiUrl(`/properties/v4/groups/${objectTypeId}?includeProperties=true`, true),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
}
export function quickFetchPropertiesFromV4(objectTypeId) {
  makePropertiesV4EarlyRequest(objectTypeId);
}
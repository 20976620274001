import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["url"],
  _excluded2 = ["url"];
import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import { addBUtoFilterGroups } from 'framework-listing-lib/earlyRequester/buNavIntegrationEarlyRequester';
function getQuickFetchName(objectTypeId) {
  return `fetch-crm-objects-${objectTypeId}`;
}
function quickFetchGraphQL(variables) {
  makeGqlEarlyRequest({
    url: quickFetch.getApiUrl('/graphql/crm'),
    operation: {
      operationName: 'ListingLibCrmSearchQuery',
      // @ts-expect-error defined in webpack
      query: importGql('ListingLibCrmSearchQuery'),
      variables
    }
  });
}
export function quickFetchCrmObjects(searchOptions, ajaxOptions) {
  const _ref = ajaxOptions || {},
    {
      url = '/crm-search/search'
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  quickFetch.makeEarlyRequest(getQuickFetchName(searchOptions.objectTypeId), Object.assign({
    url: quickFetch.getApiUrl(url, true),
    data: JSON.stringify(searchOptions),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: 'POST'
  }, rest));
  quickFetchGraphQL({
    count: searchOptions.count,
    filterGroups: searchOptions.filterGroups,
    objectTypeId: searchOptions.objectTypeId,
    offset: searchOptions.offset,
    query: searchOptions.query,
    sorts: searchOptions.sorts
  });
}

/**
 * This is a variant of @quickFetchCrmObjects.
 * It is intended **exclusively** for teams that are planning to support the BU nav.
 */
export function quickFetchCrmObjectsWithBU(searchOptions, ajaxOptions) {
  quickFetchCrmObjects(Object.assign({}, searchOptions, {
    filterGroups: addBUtoFilterGroups(searchOptions.filterGroups)
  }), ajaxOptions);
}
export function quickFetchInitialState(searchOptions, ajaxOptions) {
  const _ref2 = ajaxOptions || {},
    {
      url = '/crm-search/search'
    } = _ref2,
    rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  const emptyStateSearchOptions = {
    count: 1,
    filterGroups: searchOptions.filterGroups || [{
      filters: []
    }],
    objectTypeId: searchOptions.objectTypeId,
    offset: 0,
    query: '',
    requestOptions: {
      properties: ['hs_object_id']
    },
    sorts: [{
      order: 'DESC',
      property: 'hs_created_at'
    }]
  };
  quickFetch.makeEarlyRequest(`${getQuickFetchName(searchOptions.objectTypeId)}-initial-state`, Object.assign({
    url: quickFetch.getApiUrl(url, true),
    data: JSON.stringify(emptyStateSearchOptions),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: 'POST'
  }, rest));
  quickFetchGraphQL({
    count: emptyStateSearchOptions.count,
    filterGroups: emptyStateSearchOptions.filterGroups,
    objectTypeId: emptyStateSearchOptions.objectTypeId,
    offset: emptyStateSearchOptions.offset,
    query: emptyStateSearchOptions.query,
    sorts: emptyStateSearchOptions.sorts
  });
}

/**
 * This is a variant of @quickFetchInitialState.
 * It is intended **exclusively** for teams that are planning to support the BU nav.
 */
export function quickFetchInitialStateWithBU(searchOptions, ajaxOptions) {
  quickFetchInitialState(Object.assign({}, searchOptions, {
    filterGroups: addBUtoFilterGroups(searchOptions.filterGroups)
  }), ajaxOptions);
}
import quickFetch from 'quick-fetch';
import { getPinnedViewsQuickFetch } from 'customer-data-views-management/pinnedViews/api/getPinnedViews';
function quickFetchSalesViews({
  objectTypeId,
  namespace
}) {
  const quickFetchKey = `crm-listing-views-${objectTypeId}-${namespace}-v4`;
  quickFetch.makeEarlyRequest(quickFetchKey, {
    contentType: 'application/json',
    data: JSON.stringify({
      categories: ['DEFAULT', 'MINE', 'OTHERS'],
      count: 2000,
      offset: 0,
      query: ''
    }),
    dataType: 'json',
    timeout: 15000,
    type: 'POST',
    url: quickFetch.getApiUrl(`/sales/v4/views/by-category/search/${objectTypeId}?namespace=${namespace}`, true)
  });
}
function quickFetchPinnedViews({
  objectTypeId,
  namespace
}) {
  const quickFetchKey = `crm-listing-pinned-views-${objectTypeId}-${namespace}-v4`;
  quickFetch.makeEarlyRequest(quickFetchKey, {
    contentType: 'application/json',
    dataType: 'json',
    timeout: 15000,
    url: quickFetch.getApiUrl(`/sales/v4/views/${objectTypeId}/pinned/view?namespace=${namespace}&count=50`, true)
  });
}
export function quickFetchViews({
  namespace = 'NONE',
  objectTypeId
}) {
  getPinnedViewsQuickFetch({
    objectTypeId,
    namespace
  });
  quickFetchSalesViews({
    objectTypeId,
    namespace
  });
  quickFetchPinnedViews({
    objectTypeId,
    namespace
  });
}
/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { buildQuickFetchClientInstance } from './buildQuickFetchClientInstance';
export { makeGroupsWithPropertiesQuickFetchKey, getGroupsWithPropertiesFetchUrl, makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl, makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntriesFetchUrl, typeMetadataEntriesQuickFetchSmuggleKey, makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makePipelinesFetchKey, makePipelinesFetchUrl } from './buildQuickFetchClientInstance';
export { readSmuggledData } from './smuggleData';
let instance;
export const getFrameworkDataQuickFetchClient = () => {
  if (!instance) {
    instance = buildQuickFetchClientInstance();
  }
  return instance;
};
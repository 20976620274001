import { emailListingQuickFetch } from './listingEarlyRequester';
import { getQueryFromSearch, getViewFromPathname, isFolderPathname, isAffectedEmailsPathname, getWindowPathname, getWindowSearch } from './utils';
export default (() => {
  const pathname = getWindowPathname();
  const view = getViewFromPathname(pathname);
  const search = getWindowSearch();
  const query = getQueryFromSearch(search);
  if (!isFolderPathname(pathname) && !isAffectedEmailsPathname(pathname)) {
    void emailListingQuickFetch({
      view,
      query
    });
  }
});
import { MARKETING_EMAIL_OBJECT_TYPE, QUICK_FETCH_VIEWS } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import { NAMESPACE } from 'email-management-lib/refactor/constants/manage/shared';
import { quickFetchCrmObjects, quickFetchCrmObjectsWithBU, quickFetchInitialState, quickFetchInitialStateWithBU } from 'framework-listing-lib/earlyRequester/crmObjectsEarlyRequester';
import { quickFetchListingLib } from 'framework-listing-lib/earlyRequester/listingLibEarlyRequester';
import { quickFetchViews } from 'framework-listing-lib/earlyRequester/viewsEarlyRequester';
import quickFetch from 'quick-fetch';
import Raven from 'raven-js';
import { Metrics } from 'email-management-lib/shared/utils/metrics';
import { getEarlyViewsRequest, getSearchOptions } from './utils';
export async function emailListingQuickFetch({
  view,
  query
}) {
  try {
    var _earlyViewsResponse$r;
    Metrics.timer('earlyRequest-startTime').update(performance.now());
    quickFetchListingLib(MARKETING_EMAIL_OBJECT_TYPE);
    quickFetchViews({
      objectTypeId: MARKETING_EMAIL_OBJECT_TYPE,
      namespace: NAMESPACE
    });
    const authPromise = new Promise(resolve => {
      // @ts-expect-error wrong afterAuth type?
      quickFetch.afterAuth(auth => resolve(auth));
    });

    // This promise will always resolve
    const earlyViewsRequestPromise = new Promise(resolve => {
      const viewsRequest = getEarlyViewsRequest();
      if (viewsRequest) {
        viewsRequest.whenFinished(response => resolve(response));
        viewsRequest.onError(() => resolve(undefined));
      } else {
        resolve(undefined);
      }
    });
    const {
      user: currentUser
    } = await authPromise;
    const hasBusinessUnitAccess = currentUser.scopes.includes('business-units-access');

    // @ts-expect-error string type mismatch
    if (view && QUICK_FETCH_VIEWS.includes(view)) {
      const searchOptions = getSearchOptions({
        view,
        query,
        currentUserId: currentUser.user_id
      });
      makeQuickFetch({
        searchOptions,
        hasBusinessUnitAccess
      });
      return;
    }
    const earlyViewsResponse = await earlyViewsRequestPromise;
    if (!earlyViewsResponse || !((_earlyViewsResponse$r = earlyViewsResponse.results) !== null && _earlyViewsResponse$r !== void 0 && _earlyViewsResponse$r.length)) return;
    let viewToFetch;
    /* If we have a view id that isnt included in the default list of QUICK_FETCH_VIEWS
     * it means its a custom view, and we can fetch it using the early views response.
     * If we dont have a view id it means it was not specified via URL,
     * meaning the accessed route is one of the following:
     * - /email/{portalId}
     * - /email/{portalId}/manage
     * - /email/{portalId}/manage/state
     * In this scenario, we'll early request the left-most view tab
     */
    if (view) {
      viewToFetch = earlyViewsResponse.results.find(({
        id
      }) => id.toString() === view);
    } else {
      viewToFetch = earlyViewsResponse.results[0];
    }
    if (viewToFetch) {
      const searchOptions = getSearchOptions({
        view: viewToFetch,
        query,
        currentUserId: currentUser.user_id
      });
      makeQuickFetch({
        searchOptions,
        hasBusinessUnitAccess
      });
    }
  } catch (err) {
    Raven.captureException(err);
  }
}
function makeQuickFetch({
  searchOptions,
  hasBusinessUnitAccess
}) {
  const quickFetchStateFunction = hasBusinessUnitAccess ? quickFetchInitialStateWithBU : quickFetchInitialState;

  /**
   * quickFetchInitialState/quickFetchInitialStateWithBU does not really represent initial state for a page load
   * so we have to leave out any specific user filter
   * Read more: https://hubspot.slack.com/archives/C021HGE3YNT/p1716368870819549
   */
  const stateStart = performance.now();
  Metrics.timer('earlyRequest-initialState-startTime').update(stateStart);
  quickFetchStateFunction({
    objectTypeId: searchOptions.objectTypeId,
    filterGroups: []
  }, {
    url: '/cosemail/v1/crm-search/search',
    whenFinished: () => {
      Metrics.counter('earlyRequest-initialState-successCount').increment(1);
      Metrics.timer('earlyRequest-initialState-successTime').update(performance.now() - stateStart);
    },
    onError: () => {
      Metrics.counter('earlyRequest-initialState-errorCount').increment(1);
      Metrics.timer('earlyRequest-initialState-errorTime').update(performance.now() - stateStart);
    }
  });
  const quickFetchCRMFunction = hasBusinessUnitAccess ? quickFetchCrmObjectsWithBU : quickFetchCrmObjects;
  const crmSearchStart = performance.now();
  Metrics.timer('earlyRequest-crmSearch-startTime').update(crmSearchStart);
  quickFetchCRMFunction(searchOptions, {
    url: '/cosemail/v1/crm-search/search',
    whenFinished: () => {
      Metrics.counter('earlyRequest-crmSearch-successCount').increment(1);
      Metrics.timer('earlyRequest-crmSearch-successTime').update(performance.now() - crmSearchStart);
      // Track the performance for the entire earlyRequest process
      Metrics.timer('earlyRequest-successTime').update(performance.now());
    },
    onError: () => {
      Metrics.counter('earlyRequest-crmSearch-errorCount').increment(1);
      Metrics.timer('earlyRequest-crmSearch-errorTime').update(performance.now() - crmSearchStart);
      // Track the performance for the entire earlyRequest process
      Metrics.timer('earlyRequest-errorTime').update(performance.now());
    }
  });
}
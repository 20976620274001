import quickFetch from 'quick-fetch';
const BU_PROPERTY_NAME = 'hs_all_assigned_business_unit_ids';
function getBUFromLocalStorage() {
  let lsValue = '';
  try {
    lsValue = String(localStorage.getItem('SELECTED_BUSINESS_UNIT') || '{}');
  } catch (_unused) {
    lsValue = '{}';
  }
  let parsedValue = {};
  try {
    parsedValue = JSON.parse(lsValue);
  } catch (_unused2) {
    parsedValue = {};
  }

  // legacy number value
  if (typeof parsedValue === 'number') {
    return String(parsedValue);
  }
  // @ts-expect-error legacy ALL value
  if (parsedValue === 'ALL') {
    return 'ALL';
  }
  const currentPortalId = quickFetch.getPortalId();
  if (currentPortalId && currentPortalId in parsedValue) {
    return String(parsedValue[currentPortalId]).replaceAll('"', '');
  }
  return '0';
}

/**
 * Removes any pre-existing filter operators on the BU property
 * and adds a new filter based on the value from localStorage
 */
export function addBUtoFilterGroups(filterGroups) {
  const buValue = getBUFromLocalStorage();

  // Make sure we have at least one filter group to map over
  const filterGroupsWithGroup = filterGroups !== null && filterGroups !== void 0 && filterGroups.length ? filterGroups : [{
    filters: []
  }];
  const filterGroupsWithoutBU = filterGroupsWithGroup.map(filterGroup => Object.assign({}, filterGroup, {
    filters: [...filterGroup.filters.filter(filterOperator => filterOperator.property !== BU_PROPERTY_NAME)]
  }));
  if (buValue === 'ALL') {
    return filterGroupsWithoutBU;
  }
  return filterGroupsWithoutBU.map(filterGroup => Object.assign({}, filterGroup, {
    filters: [...filterGroup.filters, {
      operator: 'EQ',
      property: BU_PROPERTY_NAME,
      value: buValue
    }]
  }));
}